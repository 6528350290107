import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const ImpactStatementExperiment = ({ fields: { header, primaryImage } }) => {
  return (
    <section className={s.container}>
      <div className={s.text}>{header}</div>
      <CmsImage
        className={s.image}
        fields={primaryImage?.fields}
        mobileFields={primaryImage?.fields}
        useNextImage
      />
    </section>
  )
}

export default ImpactStatementExperiment
export const ImpactStatement = ImpactStatementExperiment
