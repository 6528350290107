import { useMutation, useApolloClient } from '@apollo/client'
import Router from 'next/router'
import { setCookie } from 'nookies'
import * as R from 'ramda'
import { useContext, useState, useEffect } from 'react'

import { interpolateCoachContent } from 'utilities/cms-page'

import { ClientSignUpAnalytics } from 'components/client-sign-up/analytics'
import SignUpForm from 'components/shared/sign-up-form'
import SignUpTestimonial from 'components/homepage/sign-up/testimonial'
import { SESSION_TOKEN } from 'constants/cookies'
import { DAY } from 'constants/seconds'
import CmsPageContext from 'contexts/cms-page-context'
import useCurrentUser from 'hooks/use-current-user'
import urls from 'utilities/urls'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedSectionView } from 'components/shared/analytics'
import { COACH_PROFILE_SIGN_UP_COPY } from './constants'
import {
  createAuthToken as createAuthTokenMutation,
  submitClientSignUpSurvey as submitClientSignUpSurveyMutation,
} from './mutations.graphql'
import { CoachProfileSignUpAnalytics } from './analytics'
import { useLeadsContext } from 'contexts/leads-context'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import s from './styles.module.css'

const CoachProfileSignUp = ({ fields }) => {
  const apolloClient = useApolloClient()

  const {
    signUpSurveyQuestions,
    surveyId,
    query,
    getRef,
    couponCode,
    selectedPriceId,
    coach,
    coach: { id: coachId },
  } = useContext(CmsPageContext)
  const me = useCurrentUser().data?.me
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [isReengagement, setIsReengagement] = useState(false)
  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const [createAuthToken] = useMutation(createAuthTokenMutation)
  const { subHeader } = fields
  const header = interpolateCoachContent(coach, fields.header)
  const { id, ...otherParams } = query
  const { getLeadInfo, setLeadId } = useLeadsContext()

  useEffect(() => {
    CoachProfileSignUpAnalytics.onViewPage({
      coachId,
    })
  }, [coachId])

  const trialDays = useClientTrialDays()

  const handleSubmit = ({
    utcOffset,
    fitnessGoals,
    responseParams,
    smsOptIn,
  }) => {
    const submit = async () => {
      setIsSubmitting(true)
      try {
        const {
          data: {
            submitClientSignUpSurvey: { sessionToken, paymentToken, leadId },
          },
        } = await submitClientSignUpSurvey({
          variables: {
            ref: getRef(),
            gclid: query.gclid,
            msclkid: query.msclkid,
            fbclid: query.fbclid,
            utcOffset,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fitnessGoals,
            responses: responseParams,
            referredByCoachId: coachId,
            isCoachFreeTrial: !!trialDays,
            couponCode,
            smsOptIn,
            ...getLeadInfo(),
          },
        })

        setLeadId(leadId)

        await setCookie({}, SESSION_TOKEN, sessionToken, {
          path: '/',
          maxAge: 30 * DAY,
        })

        CoachProfileSignUpAnalytics.onSignUp({
          coachId,
        })

        Router.push(
          urls.subscribe({
            token: paymentToken,
            params: {
              isPrePay: 1,
              referredByCoachId: coachId,
              priceId: selectedPriceId || undefined,
              ...otherParams,
            },
          })
        )
      } catch (serverError) {
        setIsSubmitting(false)

        if (R.test(/phone/, serverError.message)) {
          setIsReengagement(true)

          const phoneNumberResponse = R.find(R.propEq('surveyQuestionId', 12))(
            responseParams
          )
          const phoneNumber = phoneNumberResponse?.responseValue
          if (!phoneNumber) return

          // NOTE: Commenting this out for now, if we want analytics we need to rethink how to get the userId
          // or associate the user somehow in Amplitude
          // // Fetch the userId to use for identifying this user
          // // so that their events are associated with the existing client
          // const {
          //   data: { userIdToIdentifyWith },
          // } = await apolloClient.query({
          //   query: getUserIdToIdentifyWith,
          //   variables: { phoneNumber },
          //   fetchPolicy: 'network-only',
          // })

          // // Identify the user
          // AnalyticsManager.identifyUser({
          //   me: {
          //     user: {
          //       id: userIdToIdentifyWith,
          //     },
          //   },
          // })

          ClientSignUpAnalytics.onReengagement({
            coachId,
          })
        } else {
          setError(`Something went wrong. We're fixing it now`)
        }
      }
    }

    // trigger async submit
    if (!isSubmitting) submit()
  }

  const handleContinue = () => {
    const {
      user: { id: clientUserId },
    } = me
    const continueToPayment = async () => {
      const {
        data: {
          createAuthToken: { token: paymentToken },
        },
      } = await createAuthToken({
        variables: { userId: clientUserId },
      })

      CoachProfileSignUpAnalytics.onContinueSignUp({
        coachId,
      })

      if (trialDays > 0) otherParams.ref = 'clp_coachfreetrial'

      Router.push(
        urls.subscribe({
          token: paymentToken,
          params: { referredByCoachId: coachId, isPrePay: 1, ...otherParams },
        })
      )
    }

    continueToPayment()
  }

  return (
    <AnalyticsSection name="Form">
      <section id="signUp" className={s.signUpSection}>
        <div className={s.container}>
          <h2 className={s.header}>{header}</h2>
          <TrackedSectionView />
          <div className={s.main}>
            <SignUpForm
              surveyId={surveyId}
              surveyQuestions={signUpSurveyQuestions}
              subHeader={subHeader}
              onSubmit={handleSubmit}
              onContinue={handleContinue}
              isSubmitting={isSubmitting}
              errorMessage={error}
              isReengagement={isReengagement}
              onCancelReengagement={() => setIsReengagement(false)}
            />
            <SignUpTestimonial
              name={COACH_PROFILE_SIGN_UP_COPY.name}
              title={COACH_PROFILE_SIGN_UP_COPY.title}
              testimonial={COACH_PROFILE_SIGN_UP_COPY.testimonial}
              primaryImageUrl={coach?.profileImage?.url}
            />
          </div>
        </div>
      </section>
    </AnalyticsSection>
  )
}

export default CoachProfileSignUp
