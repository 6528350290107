import { useMutation } from '@apollo/client'
import * as R from 'ramda'
import { useContext, useState } from 'react'
import urls from 'utilities/urls'
import { getAdjustedUtcOffset } from 'utilities/dates'
import { DAY } from 'constants/seconds'

import CmsPageContext from 'contexts/cms-page-context'
import { FITNESS_GOALS_QUESTION_ID_V3 } from 'constants/cms-survey'
import { Button } from 'components/design-system/button'
import {
  ArrowRightIcon,
  LoadingIcon,
} from 'components/design-system/kickoff-icons'
import Router from 'next/router'
import { setCookie } from 'nookies'
import { SESSION_TOKEN } from 'constants/cookies'
import { submitClientSignUpSurvey as submitClientSignUpSurveyMutation } from './mutations.graphql'

import s from './styles.module.css'
import { LandingPageExperimentAnalytics } from '../analytics'
import Dropdown from '../../../shared/dropdown'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import { useLeadsContext } from 'contexts/leads-context'
import {
  SPECIALTY_WEIGHTLOSS_MALE,
  SPECIALTY_WEIGHTLOSS_FEMALE,
  SPECIALTY_WEIGHTLOSS,
} from 'constants/cms-survey'

const GoalsDropdown = ({
  analyticProps = { location: '' },
  landingPageOptions = { overrideCTA: undefined },
  menuPlacement,
}) => {
  const {
    signUpSurveyQuestions,
    surveyId,
    query,
    getRef,
    couponCode,
    isCoachPage,
  } = useContext(CmsPageContext)
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  const { slug } = query

  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const { getLeadInfo, setLeadId } = useLeadsContext()

  const [responseId, setResponseId] = useState('default')
  const [submitting, setSubmitting] = useState(false)

  const fitnessGoalsQuestion = R.find(
    R.propEq('id', FITNESS_GOALS_QUESTION_ID_V3),
    signUpSurveyQuestions
  )
  const { responses } = fitnessGoalsQuestion
  const responseOptions = R.map(
    ({ id: value, shortResponseText: label }) => ({ value, label }),
    responses
  )

  const handleChange = selected => {
    LandingPageExperimentAnalytics.onClick({
      location: `${analyticProps.location} - Dropdown`,
    })

    setResponseId(selected.value)
  }

  const handleClick = async e => {
    e.preventDefault()

    if (isCoachPage) {
      document.querySelector('#signUp').scrollIntoView({ behavior: 'smooth' })
      window.history.pushState(null, null, '#signUp')
      LandingPageExperimentAnalytics.onClick({
        location: `Coach Page ${analyticProps.location} - CTA`,
      })
      return
    }

    if (submitting) return

    setSubmitting(true)

    const { href, as } = urls.clientSignUp({
      ...query,
      ref: getRef(),
      // questionId: 219,
    })
    Router.prefetch(href)

    const responseParams =
      responseId && responseId !== 'default'
        ? [
            {
              surveyId,
              surveyQuestionId: FITNESS_GOALS_QUESTION_ID_V3,
              surveyQuestionResponseIds: [responseId],
            },
          ]
        : slug &&
          (slug === SPECIALTY_WEIGHTLOSS_MALE ||
            slug === SPECIALTY_WEIGHTLOSS_FEMALE ||
            slug === SPECIALTY_WEIGHTLOSS)
        ? [
            {
              surveyId,
              surveyQuestionId: FITNESS_GOALS_QUESTION_ID_V3,
              surveyQuestionResponseIds: [responseOptions[0].value],
            },
          ]
        : []

    const fitnessGoals = R.pipe(
      R.find(R.propEq('id', FITNESS_GOALS_QUESTION_ID_V3)),
      R.propOr([], 'responses'),
      R.filter(({ id }) => parseInt(id) === parseInt(responseId)),
      R.map(R.prop('text'))
    )(signUpSurveyQuestions)

    const utcOffset = getAdjustedUtcOffset()

    try {
      const {
        data: {
          submitClientSignUpSurvey: { sessionToken, leadId },
        },
      } = await submitClientSignUpSurvey({
        variables: {
          ref: getRef(),
          gclid: query.gclid,
          msclkid: query.msclkid,
          fbclid: query.fbclid,
          utcOffset,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          fitnessGoals,
          responses: responseParams,
          couponCode,
          ...getLeadInfo(),
        },
      })

      setLeadId(leadId)

      LandingPageExperimentAnalytics.onClick({
        location: `${analyticProps.location} - CTA`,
      })

      await setCookie({}, SESSION_TOKEN, sessionToken, {
        path: '/',
        maxAge: 30 * DAY,
      })
      Router.push(href, as)
    } catch (serverError) {
      setSubmitting(false)
    }
  }

  const options =
    slug &&
    (slug === SPECIALTY_WEIGHTLOSS_MALE ||
      slug === SPECIALTY_WEIGHTLOSS_FEMALE ||
      slug === SPECIALTY_WEIGHTLOSS)
      ? responseOptions
      : [
          {
            value: 'default',
            label: 'What is your fitness goal?',
            isDisabled: true,
          },
          ...responseOptions,
        ]

  return (
    <div className={s.container}>
      {!isTYPGuestPass && !isGuestPass && !isCoachPage && (
        <div className={s.select}>
          <Dropdown
            options={options}
            onChange={handleChange}
            menuPlacement={menuPlacement}
          />
        </div>
      )}
      <Button
        size="small"
        className={s.cta}
        onClick={handleClick}
        disabled={submitting}>
        {isGuestPass || isTYPGuestPass
          ? 'Claim Guest Pass'
          : isCoachPage
          ? 'Get Started'
          : landingPageOptions.overrideCTA || 'Find Your Coach'}
        {submitting ? (
          <LoadingIcon className={s.spin} />
        ) : (
          <ArrowRightIcon className={s.arrowIcon} />
        )}
      </Button>
    </div>
  )
}

export default GoalsDropdown
